import React, { useState } from 'react';
import { IconButton, TooltipHost } from '@fluentui/react';
import { IIconProps } from '@fluentui/react/lib/Icon';

interface CopyButtonProps {
    textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
    const [tooltipContent, setTooltipContent] = useState('Copy to clipboard');
    const [iconProps, setIconProps] = useState<IIconProps>({ iconName: 'Copy', style: { color: 'gray' } });

    const copyToClipboard = async () => {
        // Sanitize text by removing [File*] patterns using a regular expression
        const sanitizedText = textToCopy.replace(/\[File\d+\]/g, '');

        try {
            await navigator.clipboard.writeText(sanitizedText);
            setTooltipContent('Copied!');
            setIconProps({ iconName: 'CheckMark', style: { color: 'green' } });

            setTimeout(() => {
                setTooltipContent('Copy to clipboard');
                setIconProps({ iconName: 'Copy', style: { color: 'gray' } });
            }, 2000); // Reset after 2 seconds
        } catch (err) {
            console.error('Failed to copy: ', err);
            setTooltipContent('Failed to copy');

            setTimeout(() => setTooltipContent('Copy to clipboard'), 2000); // Reset after 2 seconds
        }
    };

    return (
        <TooltipHost content={tooltipContent}>
            <IconButton iconProps={iconProps} onClick={copyToClipboard} />
        </TooltipHost>
    );
};

export default CopyButton;
