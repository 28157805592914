import { Routes, Route, useNavigate } from "react-router-dom";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
    InteractionType,
    IPublicClientApplication
} from "@azure/msal-browser";
import { CustomNavigationClient } from "./NavigationClient";
import NoPage from "./pages/NoPage";
import Content from "./pages/content/Content";
import { Layout } from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { useEffect, useState } from "react";
import { callMsGraph } from "./graph";
import { loginRequest } from "./authConfig";
import { Loading } from "./ui-components/Loading";
import { ErrorComponent } from "./ui-components/ErrorComponent";
import { msalInstance } from "./index"; // Ensure msalInstance is correctly imported

type AppProps = {
    pca: IPublicClientApplication;
};

export type GraphData = {
    displayName: string,
    jobTitle: string,
    mail: string,
    businessPhones: string[],
    officeLocation: string,
    userPrincipalName: string
};

function App({ pca }: AppProps) {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState<null | GraphData>(null);
    const [isLoading, setIsLoading] = useState(true); // Loading state to manage waiting

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            const activeAccount = msalInstance.getActiveAccount();
            console.log("Active account =", activeAccount)
            if (activeAccount && !graphData) {
                //console.log("Active account =", activeAccount)
                callMsGraph().then(response => setGraphData(response)).catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginRequest,
                            account: activeAccount
                        });
                    }
                }).finally(() => setIsLoading(false));
            } else {
                setIsLoading(false);
            }
        }
    }, [inProgress, graphData, instance]);

    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading}
            >
                {isLoading ? (
                    <Loading /> // Show loading component while waiting for authentication
                ) : (
                    <AuthenticatedTemplate>
                        <Routes>
                            <Route path="/" >
                                <Route element={<Layout />}>
                                    <Route index element={<Chat username={msalInstance.getActiveAccount()?.username} />} />
                                    <Route path="content" element={<Content />} />
                                </Route>
                            </Route>
                            <Route path="*" element={<NoPage />} />
                        </Routes>
                    </AuthenticatedTemplate>
                )}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}

export default App;
