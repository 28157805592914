import { useEffect, useState, useRef, useCallback } from "react";
import { Pivot, PivotItem, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styles from "./AnalysisPanel.module.css";

import { ChatResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { any } from "prop-types";

interface Props {
  className: string;
  activeTab: AnalysisPanelTabs;
  onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
  activeCitation: string | undefined;
  sourceFile: string | undefined;
  pageNumber: string | undefined;
  citationHeight: string;
  answer: ChatResponse;
  onClose: () => void;
}

// Set the worker source to the local file for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

const PdfViewer = ({ sourceFile, pageNumber, citationHeight }: { sourceFile: string; pageNumber: any; citationHeight: string }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const pageRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [file, setFile] = useState<string | null>(null);
  const [ViewPDF, setViewPDF] = useState(false);
  useEffect(() => setFile(sourceFile), [sourceFile]);

  // Detect Safari browser
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const goToPage = () => {
    if (pageRefs.current[pageNumber]) {
      pageRefs.current[pageNumber]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onPageLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setViewPDF(true);
    setTimeout(() => goToPage(), 3000);
  };


  return isSafari ? (
    <div ref={containerRef} className="overflow-scroll h-full w-full">
      <Document file={file} onLoadSuccess={onPageLoadSuccess}>
        {Array(...Array(numPages))
          .map((x, i) => i + 1)
          .map((page) => (
            <div
              key={page}
              ref={(el) => {
                pageRefs.current[page] = el as any;
              }}
            >
              <Page className="border-2 border-gray-800 m-1" pageNumber={page} />
              <p className="text-center text-xs font-medium mt-2 mb-4">
                Page {page} of {numPages}
              </p>
            </div>
          ))}
      </Document>
    </div>
  ) : (
    // Use <object> for non-Safari browsers
    <object
      key={`pdf-${sourceFile}-${pageNumber}`}
      data={`${sourceFile}#page=${pageNumber}&t=${Date.now()}`}
      type="application/pdf"
      width="100%"
      height={citationHeight}
    />
  );
};



export const AnalysisPanel = ({
  answer,
  activeTab,
  activeCitation,
  sourceFile,
  pageNumber,
  citationHeight,
  className,
  onActiveTabChanged,
  onClose,
}: Props) => {
  const [markdownContent, setMarkdownContent] = useState('');
  const [plainTextContent, setPlainTextContent] = useState('');

  const sourceFileExt: string | undefined = sourceFile?.split("?")[0].split(".").pop();
  const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);

  useEffect(() => {
    if (!sourceFile) return;

    const fetchMarkdownContent = async () => {
      try {
        const response = await fetch(sourceFile!);
        const content = await response.text();
        setMarkdownContent(content);
      } catch (error) {
        console.error('Error fetching Markdown content:', error);
      }
    };

    fetchMarkdownContent();
  }, [sourceFile]);

  useEffect(() => {
    const fetchPlainTextContent = async () => {
      try {
        const response = await fetch(sourceFile!);
        const content = await response.text();
        setPlainTextContent(content);
      } catch (error) {
        console.error('Error fetching plain text content:', error);
      }
    };

    if (["json", "txt", "xml"].includes(sourceFileExt!)) {
      fetchPlainTextContent();
    }
  }, [sourceFile, sourceFileExt]);

  return (
    <div className={`${className} ${styles.analysisPanelContainer}`}>
      <div className={styles.analysisPanelHeader}>
        <IconButton
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel="Close panel"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
          className={styles.closeButton}
        />
      </div>
      <Pivot
        className={className}
        selectedKey={activeTab}
        onLinkClick={(pivotItem) => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
      >
        <PivotItem itemKey="rawFile" headerText="Document">
          {["docx", "xlsx", "pptx"].includes(sourceFileExt!) ? (
            <iframe title="Source File" src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(sourceFile as string)}&action=embedview&wdStartOn=${pageNumber}`} width="100%" height={citationHeight} />
          ) : sourceFileExt === "pdf" ? (
            // Add a key to force re-render on sourceFile or pageNumber change
            <PdfViewer key={`${sourceFile}-${pageNumber}`} sourceFile={sourceFile!} pageNumber={pageNumber!} citationHeight={citationHeight} />
          ) : sourceFileExt === "md" ? (
            <ReactMarkdown>{markdownContent}</ReactMarkdown>
          ) : ["json", "txt", "xml"].includes(sourceFileExt!) ? (
            <pre>{plainTextContent}</pre>
          ) : (
            <iframe title="Source File" src={sourceFile} width="100%" height={citationHeight} />
          )}
        </PivotItem>
      </Pivot>
    </div>
  );
};


