import {Link, Text} from "@fluentui/react";
import {useNavigate} from "react-router-dom";
import {msalInstance} from "../index";

interface Props {
    errorMsg?: string;
}

export const ErrorPage = ({ errorMsg }: Props) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        msalInstance.clearCache();
    };
    
    return <div style={{display: "flex", gap: 4, flexDirection: "column", padding: 16}}>
        <Link href={"/"} onClick={handleGoBack} >
            Go Back 
        </Link>
        <Text variant={"large"}>{errorMsg}</Text>
    </div>
}